// Library Imports
import React, { Component } from "react";
import { Line } from "rc-progress";
//! Unused Imports
// import { ContactlessOutlined } from "@material-ui/icons";
// import { zhCN } from "date-fns/locale";

export class UserProfileComplitionBarCommon extends Component {
  constructor(props) {
    super(props);
    let peraddress;
    let corsAddress;
    this.props.personnelAddressData&&this.props.personnelAddressData.forEach(function (addr) {
      if (addr.addressType == "permanent") {
        peraddress = addr;
      }
    });
    this.props.personnelAddressData&&this.props.personnelAddressData.forEach(function (addr) {
      if (addr.addressType == "correspondence") {
        corsAddress = addr;
      }
    });
    let userProfileCompletedData={
      empNo:this.props?.personnelProfileData?.empNo,
      panNumber: this.props?.personnelProfileData?.panNumber,
      mobileNo: this.props?.personnelProfileData?.mobileNo,
      firstName: this.props?.personnelProfileData?.firstName,
      lastName: this.props?.personnelProfileData?.lastName,
      // salutation:this.props?.personnelProfileData?.salutation,
      middleName: this.props?.personnelProfileData?.middleName,
      fatherName: this.props?.personnelProfileData?.learnerFatherName,
      // maritalStatus:this.props?.personnelProfileData?.maritalStatus,
      // secondaryEmailID:this.props?.personnelProfileData?.secondaryEmailID,
      dob: this.props?.personnelProfileData?.dob,
      gender: this.props?.personnelProfileData?.gender,
      emailID: this.props?.personnelProfileData?.emailID,
      ARNNO:this.props?.personnelProfileData?.arn,
      orgCompanyName:this.props?.personnelProfileData?.companyName,
      profilePhotoURL: this.props?.personnelProfileData?.profilePhotoURL,
    }
    let userProfileAddressData = {
  
    };
    let EducationalDetails={
      learnerQualificationpassingYear: (
        this.props.personnelProfileData?.learnerQualification?.[0] || ""
      )?.passingYear,
      learnerQualificationqualificationType: (
        this.props.personnelProfileData?.learnerQualification?.[0] || ""
      )?.qualificationType,
      learnerQualificationinstituteName: (
        this.props.personnelProfileData?.learnerQualification?.[0] || ""
      )?.instituteName,
    }
    let NISMandEnrollDetails={}
    let EmploymentDetails={}
    let EUINDetails={}
    let disabledForCielops=localStorage.getItem("roleId") == 6 ||localStorage.getItem("roleId") == 7? true:false;
    if((this.props.personnelProfileData||this.props)?.learnerEuinOption==="Fresh EUIN"
    ||(this.props.personnelProfileData||this.props)?.learnerEuinOption==="Only EUIN Renewal"){
    if((this.props.personnelProfileData||this.props)?.learnerEuinOption==="Only EUIN Renewal"){
      userProfileCompletedData={
        ...userProfileCompletedData,   
        panCardURL: this.props.personnelProfileData?.panCardURL,
      };
      EducationalDetails={
        ...EducationalDetails,
        learnerQualificationpercentage: (
          this.props.personnelProfileData?.learnerQualification?.[0] || ""
        )?.percentage,
      }
      const occupationValue = this.props.personnelProfileData?.learnerProfessionalQualification?.[0]?.occupation;
      const isStudent = occupationValue&&[1021, "1021"].includes(occupationValue)?true:false
        if(isStudent){
       EmploymentDetails={
           ...EmploymentDetails,
           professionalQualificationoccupation: (
             this.props.personnelProfileData?.learnerProfessionalQualification?.[0] || ""
           )?.occupation,
       }
      }
       else{
         EmploymentDetails={
           ...EmploymentDetails,
           professionalQualificationoccupation: (
             this.props.personnelProfileData?.learnerProfessionalQualification?.[0] || ""
           )?.occupation,
           professionalQualificationcompanyName: (
             this.props.personnelProfileData?.learnerProfessionalQualification?.[0] || ""
           )?.companyName,
           professionalQualificationdesignation: (
             this.props.personnelProfileData?.learnerProfessionalQualification?.[0] || ""
           )?.designation,
           professionalQualificationtotalExperience: (
             this.props.personnelProfileData?.learnerProfessionalQualification?.[0] || ""
           )?.totalExperience,
         }
       }
    }
      let isPersonalDetailsDisabled=(this.props.personnelProfileData||this.props)?.learnerEuinOption ==="Only EUIN Renewal" ||disabledForCielops? true:false
      if ((isPersonalDetailsDisabled&&this.props.personnelProfileData?.salutation)||!isPersonalDetailsDisabled) {
        userProfileCompletedData={
         ...userProfileCompletedData,   
         salutation:this.props.personnelProfileData?.salutation,
       };
     }
     if ((isPersonalDetailsDisabled&&this.props.personnelProfileData?.maritalStatus)||!isPersonalDetailsDisabled) {
        userProfileCompletedData={
         ...userProfileCompletedData,   
         maritalStatus:this.props.personnelProfileData?.maritalStatus,
       };
     }
     if ((isPersonalDetailsDisabled&&this.props.personnelProfileData?.secondaryEmailID)||!isPersonalDetailsDisabled) {
       userProfileCompletedData={
         ...userProfileCompletedData,   
         secondaryEmailID:this.props.personnelProfileData?.secondaryEmailID,
       };
     }
     let isAddressHide=(this.props.personnelProfileData||this.props)?.learnerEuinOption ==="Only EUIN Renewal"?true:false
     if ((isAddressHide&&peraddress?.street)||!isAddressHide) {
       userProfileAddressData={
        ...userProfileAddressData,   
        perStreet: peraddress ? peraddress?.street : '',
      };
    }   
    if ((isAddressHide&&peraddress?.street2)||!isAddressHide) {
       userProfileAddressData={
        ...userProfileAddressData,   
        perStreet2: peraddress ? peraddress?.street2 : '',
      };
    }   
      
    if ((isAddressHide&&peraddress?.street3)||!isAddressHide) {
       userProfileAddressData={
        ...userProfileAddressData,   
        perStreet3: peraddress ? peraddress?.street3 : '',
      };
    }   
    if((this.props.personnelProfileData||this.props)?.learnerEuinOption==="Fresh EUIN"){  
    if ((isAddressHide&&peraddress?.cityId)||!isAddressHide) {
       userProfileAddressData={
        ...userProfileAddressData,   
        percityId: peraddress ? peraddress?.cityId : '',
      };
    }   
      
    if ((isAddressHide&&peraddress?.stateId)||!isAddressHide) {
       userProfileAddressData={
        ...userProfileAddressData,   
        perstateId: peraddress ? peraddress?.stateId : '',
      };
    }   
      
    if ((isAddressHide&&(peraddress?.countryId||1))||!isAddressHide) {
       userProfileAddressData={
        ...userProfileAddressData,   
        percountryId: peraddress ? peraddress?.countryId?peraddress?.countryId:1: 1,
      };
    } 
  }  
        
    if ((isAddressHide&&peraddress?.postalCode)||!isAddressHide) {
      console.log("vkd isAddressHide",isAddressHide,peraddress?.postalCode,!isAddressHide,((isAddressHide&&peraddress?.postalCode)||!isAddressHide))
       userProfileAddressData={
        ...userProfileAddressData,   
        perPostalCode: peraddress ? peraddress?.postalCode : '',
      };
    }  
        
    if ((isAddressHide&&peraddress?.addressTypeMark)||!isAddressHide) {
       userProfileAddressData={
        ...userProfileAddressData,   
        addressTypeMark:peraddress?peraddress?.addressTypeMark:'',
      };
    }   
        let CertificateNumberDisabled,EnrollmentDateDisabled,EnrollmentNumberDisabled,CertificateValidUptoDisabled;
        if((this.props.personnelProfileData||this.props)?.learnerEuinOption==="Fresh EUIN"){
          CertificateValidUptoDisabled=disabledForCielops === true ? false : (this.props?.personnelProfileData?.certificateValidUpto === "" || this.props?.personnelProfileData?.certificateValidUpto === undefined ? false : true)
          EnrollmentDateDisabled =disabledForCielops === true ? false : (this.props?.personnelProfileData?.enrollmentDate === "" || this.props?.personnelProfileData?.enrollmentDate === undefined ? false || this.props?.personnelProfileData?.learnerEuinOption === "Only EUIN Renewal" : true)
          EnrollmentNumberDisabled =disabledForCielops === true ? false : (this.props?.personnelProfileData?.enrollmentNumber === "" || this.props?.personnelProfileData?.enrollmentNumber === undefined ? false || this.props?.personnelProfileData?.learnerEuinOption === "Only EUIN Renewal" : true)
          CertificateNumberDisabled =disabledForCielops === true ? false : (this.props?.personnelProfileData?.certificateNumber === "" || this.props?.personnelProfileData?.certificateNumber === undefined ? false : true)
        }
        else{
          CertificateValidUptoDisabled=disabledForCielops === true ? false : (this.props?.personnelProfileData?.certificateValidUpto === "" || this.props?.personnelProfileData?.certificateValidUpto === undefined ? false : true)
          EnrollmentDateDisabled =disabledForCielops === true ? false : (this.props?.personnelProfileData?.enrollmentDate === "" || this.props?.personnelProfileData?.enrollmentDate === undefined ? false|| (this.props.personnelProfileData||this.props)?.learnerEuinOption==="Fresh EUIN" || this.props?.personnelProfileData?.learnerEuinOption === "Only EUIN Renewal" : true)
          EnrollmentNumberDisabled =disabledForCielops === true ? false : (this.props?.personnelProfileData?.enrollmentNumber === "" || this.props?.personnelProfileData?.enrollmentNumber === undefined ? false || (this.props.personnelProfileData||this.props)?.learnerEuinOption==="Fresh EUIN" || this.props?.personnelProfileData?.learnerEuinOption === "Only EUIN Renewal" : true)
          CertificateNumberDisabled =disabledForCielops === true ? false : (this.props?.personnelProfileData?.certificateNumber === "" || this.props?.personnelProfileData?.certificateNumber === undefined ? false : true) 
        }
        if ((CertificateValidUptoDisabled&&this.props?.personnelProfileData?.certificateValidUpto)||!CertificateValidUptoDisabled) {
          NISMandEnrollDetails = {
            ...NISMandEnrollDetails,
            certificateValidUpto: this.props?.personnelProfileData?.certificateValidUpto,
          };
        }
        if ((CertificateNumberDisabled&&this.props?.personnelProfileData?.nismRegistrationNo)||!CertificateNumberDisabled) {
          NISMandEnrollDetails = {
            ...NISMandEnrollDetails,
            nismRegistrationNo: this.props?.personnelProfileData?.nismRegistrationNo,
          };
        }
        if ((EnrollmentNumberDisabled&&this.props?.personnelProfileData?.enrollmentNumber)||!EnrollmentNumberDisabled) {
          NISMandEnrollDetails = {
            ...NISMandEnrollDetails,
            enrollmentNumber: this.props?.personnelProfileData?.enrollmentNumber,
          };
        }
        if ((EnrollmentDateDisabled&&this.props?.personnelProfileData?.enrollmentDate)||!EnrollmentDateDisabled) {
          NISMandEnrollDetails = {
            ...NISMandEnrollDetails,
            enrollmentDate: this.props?.personnelProfileData?.enrollmentDate,
          };
        }  
        let isEUINDisabled= disabledForCielops === true? false:this.props.personnelProfileData?.euinNumber ===""||this.props.personnelProfileData?.euinNumber ===undefined? false:true
        if ((isEUINDisabled&&this.props.personnelProfileData?.euinNumber)||!isEUINDisabled) {
          if(["Only EUIN Renewal"].includes((this.props.personnelProfileData||this.props)?.learnerEuinOption)){
          EUINDetails = {
            ...EUINDetails,
            euinNumber: this.props.personnelProfileData?.euinNumber,
          };
        }
      
        } 
      }
    if(!((this.props.personnelProfileData||this.props)?.learnerEuinOption==="Fresh EUIN"
    ||(this.props.personnelProfileData||this.props)?.learnerEuinOption==="Only EUIN Renewal")){      
      userProfileCompletedData={
        ...userProfileCompletedData,
       salutation:this.props?.personnelProfileData?.salutation,
      maritalStatus:this.props?.personnelProfileData?.maritalStatus,
      secondaryEmailID:this.props?.personnelProfileData?.secondaryEmailID,
      }
      userProfileAddressData = {
        ...userProfileAddressData,
      perStreet: peraddress ? peraddress?.street : '',
      perStreet2: peraddress ? peraddress?.street2 : '',
      percityId: peraddress ? peraddress?.cityId : '',
      perstateId: peraddress ? peraddress?.stateId : '',
      percountryId: peraddress ? peraddress?.countryId?peraddress?.countryId:1: 1,
      perPostalCode: peraddress ? peraddress?.postalCode : '',
      perStreet3: peraddress ? peraddress?.street3 : '',
      addressTypeMark:peraddress?peraddress?.addressTypeMark:'',
      addressProofURL: this.props.personnelProfileData?.proofOfAddressURL,
      peraddressproof1: this.props.personnelProfileData?.proofOfAddress,
      }
      EducationalDetails={
        ...EducationalDetails,
        learnerQualificationpercentage: (
          this.props.personnelProfileData?.learnerQualification?.[0] || ""
        )?.percentage,
      }
      const occupationValue = this.props.personnelProfileData?.learnerProfessionalQualification?.[0]?.occupation;
     const isStudent = occupationValue&&[1021, "1021"].includes(occupationValue)?true:false
       if(isStudent){
      EmploymentDetails={
          ...EmploymentDetails,
          professionalQualificationoccupation: (
            this.props.personnelProfileData?.learnerProfessionalQualification?.[0] || ""
          )?.occupation,
      }
     }
      else{
        EmploymentDetails={
          ...EmploymentDetails,
          professionalQualificationoccupation: (
            this.props.personnelProfileData?.learnerProfessionalQualification?.[0] || ""
          )?.occupation,
          professionalQualificationcompanyName: (
            this.props.personnelProfileData?.learnerProfessionalQualification?.[0] || ""
          )?.companyName,
          professionalQualificationdesignation: (
            this.props.personnelProfileData?.learnerProfessionalQualification?.[0] || ""
          )?.designation,
          professionalQualificationtotalExperience: (
            this.props.personnelProfileData?.learnerProfessionalQualification?.[0] || ""
          )?.totalExperience,
        }
      }
    
      userProfileCompletedData={
        ...userProfileCompletedData,
        panCardURL: this.props.personnelProfileData?.panCardURL,
      }
      let CertificateNumberDisabled,EnrollmentDateDisabled,EnrollmentNumberDisabled,CertificateValidUptoDisabled;
      if((this.props.personnelProfileData||this.props)?.learnerEuinOption==="Fresh EUIN"){
        CertificateValidUptoDisabled=disabledForCielops === true ? false : (this.props?.personnelProfileData?.certificateValidUpto === "" || this.props?.personnelProfileData?.certificateValidUpto === undefined ? false : true)
        EnrollmentDateDisabled =disabledForCielops === true ? false : (this.props?.personnelProfileData?.enrollmentDate === "" || this.props?.personnelProfileData?.enrollmentDate === undefined ? false || this.props?.personnelProfileData?.learnerEuinOption === "Only EUIN Renewal" : true)
        EnrollmentNumberDisabled =disabledForCielops === true ? false : (this.props?.personnelProfileData?.enrollmentNumber === "" || this.props?.personnelProfileData?.enrollmentNumber === undefined ? false || this.props?.personnelProfileData?.learnerEuinOption === "Only EUIN Renewal" : true)
        CertificateNumberDisabled =disabledForCielops === true ? false : (this.props?.personnelProfileData?.certificateNumber === "" || this.props?.personnelProfileData?.certificateNumber === undefined ? false : true)
      }
      else{
        CertificateValidUptoDisabled=disabledForCielops === true ? false : (this.props?.personnelProfileData?.certificateValidUpto === "" || this.props?.personnelProfileData?.certificateValidUpto === undefined ? false : true)
        EnrollmentDateDisabled =disabledForCielops === true ? false : (this.props?.personnelProfileData?.enrollmentDate === "" || this.props?.personnelProfileData?.enrollmentDate === undefined ? false|| (this.props.personnelProfileData||this.props)?.learnerEuinOption==="Fresh EUIN" || this.props?.personnelProfileData?.learnerEuinOption === "Only EUIN Renewal" : true)
        EnrollmentNumberDisabled =disabledForCielops === true ? false : (this.props?.personnelProfileData?.enrollmentNumber === "" || this.props?.personnelProfileData?.enrollmentNumber === undefined ? false || (this.props.personnelProfileData||this.props)?.learnerEuinOption==="Fresh EUIN" || this.props?.personnelProfileData?.learnerEuinOption === "Only EUIN Renewal" : true)
        CertificateNumberDisabled =disabledForCielops === true ? false : (this.props?.personnelProfileData?.certificateNumber === "" || this.props?.personnelProfileData?.certificateNumber === undefined ? false : true)
      }
      if(["Only Mapping","EUIN Renewal and mapping"].includes((this.props.personnelProfileData||this.props)?.learnerEuinOption)){
      if ((CertificateValidUptoDisabled&&this.props?.personnelProfileData?.certificateValidUpto)||!CertificateValidUptoDisabled) {
        NISMandEnrollDetails = {
          ...NISMandEnrollDetails,
          certificateValidUpto: this.props?.personnelProfileData?.certificateValidUpto,
        };
      }
      if ((CertificateNumberDisabled&&this.props?.personnelProfileData?.nismRegistrationNo)||!CertificateNumberDisabled) {
        NISMandEnrollDetails = {
          ...NISMandEnrollDetails,
          nismRegistrationNo: this.props?.personnelProfileData?.nismRegistrationNo,
        };
      }
      if ((EnrollmentNumberDisabled&&this.props?.personnelProfileData?.enrollmentNumber)||!EnrollmentNumberDisabled) {
        NISMandEnrollDetails = {
          ...NISMandEnrollDetails,
          enrollmentNumber: this.props?.personnelProfileData?.enrollmentNumber,
        };
      }
      if ((EnrollmentDateDisabled&&this.props?.personnelProfileData?.enrollmentDate)||!EnrollmentDateDisabled) {
        NISMandEnrollDetails = {
          ...NISMandEnrollDetails,
          enrollmentDate: this.props?.personnelProfileData?.enrollmentDate,
        };
      } 
      let isEUINDisabled= disabledForCielops === true? false:this.props.personnelProfileData?.euinNumber ===""||this.props.personnelProfileData?.euinNumber ===undefined? false:true
      if ((isEUINDisabled&&this.props.personnelProfileData?.euinNumber)||!isEUINDisabled) {
          EUINDetails = {
          ...EUINDetails,
          euinNumber: this.props.personnelProfileData?.euinNumber,
        };  
    }
      }
    }
    console.log("vkd-this.props",this.props.personnelProfileData,this.props.personnelAddressData)  
    var obj1 = userProfileCompletedData;
    var keys1 = Object.keys(obj1);
    var filtered1 = keys1?.filter(function (key) {
      var value = obj1[key]||obj1.key;
      return value !== null && value !== undefined && value !== "" && !(Array.isArray(value) && value.length === 0);
    });
    var obj2 = userProfileAddressData;
    var keys2 = Object.keys(obj2);
    var filtered2 = keys2?.filter(function (key) {
      var value = obj2[key]||obj2.key;
      return value !== null && value !== undefined && value !== "" && !(Array.isArray(value) && value.length === 0);
    });
    var obj3 = EducationalDetails;
    var keys3 = Object.keys(obj3);
    var filtered3 = keys3?.filter(function (key) {
      var value = obj3[key]||obj3.key;
      return value !== null && value !== undefined && value !== "" && !(Array.isArray(value) && value.length === 0);
    });
    var obj4 = EmploymentDetails;
    var keys4 = Object.keys(obj4);
    var filtered4 = keys4?.filter(function (key) {
      var value = obj4[key]||obj4.key;
      return value !== null && value !== undefined && value !== "" && !(Array.isArray(value) && value.length === 0);
    });
    var obj5 = NISMandEnrollDetails;
    var keys5 = Object.keys(obj5);
    var filtered5 = keys5?.filter(function (key) {
      var value = obj5[key]||obj5.key;
      return value !== null && value !== undefined && value !== "" && !(Array.isArray(value) && value.length === 0);
    });
    var obj6 = EUINDetails;
    var keys6 = Object.keys(obj6);
    var filtered6 = keys6?.filter(function (key) {
      var value = obj6[key]||obj6.key;
      return value !== null && value !== undefined && value !== "" && !(Array.isArray(value) && value.length === 0);
    });
  let numer,denom;
  let personalN=filtered1?Object.keys(filtered1)?.length:0;
  let addressN=filtered2?Object.keys(filtered2)?.length:0;
  let educationN=filtered3?Object.keys(filtered3)?.length:0;
  let employmentN=filtered4?Object.keys(filtered4)?.length:0;
  let NISMandEnrollN=filtered5?Object.keys(filtered5)?.length:0;
  let EUINDetailsN=filtered6?Object.keys(filtered6)?.length:0;
  numer=personalN+addressN+educationN+employmentN+NISMandEnrollN+EUINDetailsN;
  let personalD,addressD,educationD,employmentD,NISMandEnrollD,EUINDetailsD;
  if(
  this.props.personnelProfileData.learnerEuinOption==="Fresh EUIN"
    ||this.props.personnelProfileData.learnerEuinOption==="Only EUIN Renewal"){
       personalD=obj1?Object.keys(obj1)?.length:16;
       addressD=obj2?Object.keys(obj2)?.length:8;
       educationD=obj3?Object.keys(obj3)?.length:3;
       employmentD=obj4?Object.keys(obj4)?.length:0;
       NISMandEnrollD=obj5?Object.keys(obj5)?.length:4;
       EUINDetailsD=obj6?Object.keys(obj6)?.length:0
    }
    else{
       personalD=obj1?Object.keys(obj1)?.length:17;
       addressD=obj2?Object.keys(obj2)?.length:10;
       educationD=obj3?Object.keys(obj3)?.length:4;
       employmentD=obj4?Object.keys(obj4)?.length:4;
       NISMandEnrollD=obj5?Object.keys(obj5)?.length:0;
       EUINDetailsD=obj6?Object.keys(obj6)?.length:0
    }
 
  denom=personalD+addressD+educationD+employmentD+NISMandEnrollD+EUINDetailsD;
  let combinedper = Math.round((numer / denom) * 100);
  let key="--"
  console.log("vkd-this.props",this.props.personnelProfileData,this.props.personnelAddressData);
  console.log("vkd-personal",key,userProfileCompletedData,obj1,keys1);
  console.log("vkd-address",key,userProfileAddressData,obj2,keys2);
  console.log("vkd-eduction",key,EducationalDetails,obj3,keys3);
  console.log("vkd-employment",key,EmploymentDetails,obj4,keys4);
  console.log("vkd-nism",key,NISMandEnrollDetails,obj5,keys5);
  console.log("vkd-euin",key,EUINDetails,obj6,keys6);
  console.log("vkd-numerator",key,personalN,addressN,educationN,employmentN,NISMandEnrollN,EUINDetailsN,numer);
  console.log("vkd-denominator",key,personalD,addressD,educationD,employmentD,NISMandEnrollD,EUINDetailsD,denom);
  console.log("vkd-percentage", key,combinedper);
    this.state = {
      profileComplitionPercent:["",null,undefined,NaN,"NaN"].includes(combinedper)?0:combinedper>100?100:combinedper,
      userProfileCompletedData: userProfileCompletedData,
    };
    
    localStorage.setItem("ProfilePercent", this.state.profileComplitionPercent);  
    localStorage.setItem("profilecomlitionvalue", this.state.profileComplitionPercent);  
    // profilecomlitionvalue
  }

  render() {
   
    
    return (
      <div>
        <center>
          <div className="profile-graph">
            <Line
              percent={parseInt(localStorage.getItem('profilecomlitionvalue'))}
              //{this.state.profileComplitionPercent}
              strokeWidth="4"
              strokeColor="#109619"
              width="100%"
              height="auto"
            />
          </div>
          <span className="profile-completion-title">
            Your profile is {parseInt(localStorage.getItem('profilecomlitionvalue'))}% Completed
            {/* {this.state.profileComplitionPercent}% Completed */}
          </span>
        </center>
      </div>
    );
  }
}

export default UserProfileComplitionBarCommon;
