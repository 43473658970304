import React from 'react';
import banner1 from '../../assets/images/banner1.png';
import banner2 from '../../assets/images/banner2.png';
import banner3 from '../../assets/images/banner3.jpg';
import banner4 from '../../assets/images/banner4.jpg';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FeedbackService } from '../../services/feedback.service';
import $ from "jquery";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import FinxRegistration from "../../components/mainpage/finxregistration/finxregistration";
import daplogo from '../../assets/images/dapIcon.png';
// import '../../assets/js/customjs.js'
import {
    warning,
    warningNotification,
    success,
    successNotification,
    error,
    errorNotification,
  } from "../../components/notification/notifications";
// const responsive = {
//     desktop: {
//         breakpoint: { max: 3000, min: 1024 },
//         items: 5,
//         slidesToSlide: 1, // optional, default to 1.
//         partialVisibilityGutter: 40

//     },
//     tablet: {
//         breakpoint: { max: 1024, min: 464 },
//         items: 5,
//         slidesToSlide: 1, // optional, default to 1.
//     },
//     mobile: {
//         breakpoint: { max: 464, min: 0 },
//         items: 5,
//         slidesToSlide: 1, // optional, default to 1.
//     },
// };
class cfxroadmap extends React.Component {
    //function cfxroadmap(props) {
    constructor(props) {
        super(props);
         this.state = {
            emailid: "",
            partyid : "",
            rank:0,
            feedbackMsg:"",
           typeOfConcernList:[],
           typeOfConcernList1:[  {
            concernID: 1,
            concernName: "Unable to generate OTP"
        },
        {
            concernID: 2,
            concernName: "Site is very slow"
        },
        {
            concernID: 3,
            concernName: "Not able to make the payment"
        },
        {
            concernID: 4,
            concernName: "Not able to upload the documents"
        },
        {
            concernID: 5,
            concernName: "Site is not intuitive"
        },
        {
            concernID: 6,
            concernName: "Others"
        }]
        

        }
    }
// getConcernTypeList=()=>{
    
//     FeedbackService.getConcernTypeList(
//         (cb) => {        
//             if (cb.status === "success") {
//               this.setState({ loading: false });
//               this.setState({typeOfConcernList:cb.responseListObject})
            
//             } else {        
//               error(cb.status,errorNotification);
             
//             }
//           },
//           (err) => console.log("Error", err)
//       );

// }
    componentDidMount() {

        if (localStorage.getItem("showFeedback") === "true") {
                window.$('#feedback-modal').modal('show')
                localStorage.setItem("showFeedback", "false");
        }

        var header = document.getElementById("navbarSupportedContent1");
        var btns = header.getElementsByClassName("nav-item");
        for (var i = 0; i < btns.length; i++) {
            btns[i].addEventListener("click", function () {
                var current = document.getElementsByClassName("active");
                current[0].className = current[0].className.replace(" active", "");
                this.className += " active";
            });
        }

        var header1 = document.getElementById("myDIV");
        var btns1 = header1.getElementsByClassName("emoji");
        for (var i = 0; i < btns1.length; i++) {
            btns1[i].addEventListener("click", function () {
                var current = document.getElementsByClassName("active");
                current[1].className = current[1].className.replace(" active", "");
                this.className += " active";
            });
        }

        $('body').bind('click', function (e) {
            if ($(e.target).closest('#navbarSupportedContent1').length == 0) {
                // click happened outside of .navbar, so hide
                var opened = $('.navbar-collapse').hasClass('collapse in');
                if (opened === true) {
                    $('.navbar-collapse').removeClass('in');
                }
            }
        });

        $("#hideMenu").click(function () {
            $(".navbar-collapse").removeClass("in");
        });
        $("#hideMenu1").click(function () {
            $(".navbar-collapse").removeClass("in");
        });
        $("#hideMenu2").click(function () {
            $(".navbar-collapse").removeClass("in");
        });
        $("#hideMenu3").click(function () {
            $(".navbar-collapse").removeClass("in");
        });
        $("#hideMenu4").click(function () {
            $(".navbar-collapse").removeClass("in");
        });
        $("#hideMenu5").click(function () {
            $(".navbar-collapse").removeClass("in");
        });
        $("#hideMenu6").click(function () {
            $(".navbar-collapse").removeClass("in");
        });
        $("#hideMenu7").click(function () {
            $(".navbar-collapse").removeClass("in");
        });
        $("#hideMenu8").click(function () {
            $(".navbar-collapse").removeClass("in");
        });
        
        if(this.props.data !== undefined){
            this.setState({
                email: this.props.data.email === undefined ? ""  : this.props.data.email,
                partyid: this.props.data.partyid === undefined ? "":this.props.data.partyid
            })
        }

        // this.getConcernTypeList();

    }

    render() {       
        return (
            <React.Fragment>
                
                <section className="dap-header" >
                    <nav className="navbar navbar navbar-fixed-top">
                        {/*  Brand/logo */}
                        {/* <div className="navbar-header">
                                <button type="button" className="navbar-toggle collapsed" data-toggle="modal" data-target="#flipFlop" aria-expanded="false">
                                    <span data-toggle="modal"    data-keyboard="true" data-target="#login">Login &nbsp; <i className="fa fa-sign-in"
                                        aria-hidden="true"></i></span>
                                </button> */}
                        {/* </div>  */}

                        {/* <div className="container-fluid padding-pl-pr"> */}
                        {/*  Brand/logo */}
                        {/* <div className="navbar-header">
                                <button type="button" className="navbar-toggle collapsed" data-toggle="modal" data-target="#flipFlop" aria-expanded="false">
                                    <span data-toggle="modal"    data-keyboard="true" data-target="#login">Login &nbsp; <i className="fa fa-sign-in"
                                        aria-hidden="true"></i></span>
                                </button>
                                <a className="navbar-brand" href="/"><img src={daplogo} alt="Dap Logo" /></a>
                            </div> */}
                        {/* <button id="hideMenu9" class="navbar-toggler toggler-example" type="button" data-toggle="collapse" data-target="#navbarSupportedContent1"
                            aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation"><span class="dark-blue-text"><i
                                class="fa fa-bars"></i></span></button> */}
                        <button class="navbar-toggler " type="button" data-toggle="collapse" data-target="#navbarSupportedContent1"
                            aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="dark-blue-text"><i
                                class="fa fa-bars"></i></span>
                        </button>

                        {/* <div className="col-md-12">  */}
                        <a className="navbar-brand" href="/"><img src={daplogo} alt="Dap Logo" /></a>
                        {/* </div>  */}

                        {/* <div className="col-md-12"> */}
                        <div class="collapse navbar-collapse" id="navbarSupportedContent1">

                            <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                                <li class="nav-item active" id="hideMenu">
                                    <a class="nav-link" href="#home">Home </a>
                                </li>
                                <li class="nav-item" id="hideMenu1">
                                    <a class="nav-link" href="#about">About</a>
                                </li>
                                <li class="nav-item" id="hideMenu2">
                                    <a class="nav-link" href="#benefits">Benefits</a>
                                </li>
                                <li class="nav-item" id="hideMenu3">
                                    <a class="nav-link disabled" href="#processflow">RCP Process</a>
                                </li>
                                {/* <li class="nav-item">
                                    <a class="nav-link disabled" href="#testimonials">Testimonials</a>
                                </li> */}
                                {/* <li class="nav-item" id="hideMenu4">
                                    <a class="nav-link disabled" href="#videos">Videos</a>
                                </li>
                                <li class="nav-item" id="hideMenu5">
                                    <a class="nav-link disabled" href="#referralprogram">Referral</a>
                                </li> */}
                                <li class="nav-item" id="hideMenu5">
                                    <a class="nav-link disabled" href="#referralprogram">CPE</a>
                                </li>
                                <li class="nav-item" id="hideMenu6">
                                    <a class="nav-link disabled" href="#faq">FAQs</a>
                                </li>
                                <li class="nav-item" id="hideMenu7">
                                    <a class="nav-link disabled" href="#ourpartners">Our Partners</a>
                                </li>
                                <li class="nav-item" id="hideMenu8">
                                    <a class="nav-link disabled" href="#" data-keyboard="true" data-toggle="modal"
                                        data-keyboard="true" data-target="#feat-Reg" >Login/Register
                                        {/* <i class="fa fa-sign-in" aria-hidden="true"></i> */}
                                    </a>
                                </li>
                                {/* <li class="nav-item">
                                    <a class="nav-link disabled" href="#"><i class="fa fa-search" aria-hidden="true"></i></a>
                                </li> */}
                            </ul>
                        </div>

                        {/* </div>  */}
                    </nav>

                </section>

                <section className="banner section-padding" id="home">
                    <Carousel infiniteLoop useKeyboardArrows autoPlay autoPlaySpeed={3000}>
                        {/* <div>
                            <a className="padding-01" href="#" data-keyboard="true" data-toggle="modal"
                                data-keyboard="true" data-target="#feat-Reg" >
                                <img src={banner1} />
                            </a>
                        </div> */}
                        <div>
                            <a href="#about" className="padding-01"> <img src={banner2} /> </a>
                            {/* <div className="caption">
                                <div className="col-md-7 col-sm-7 col-xs-12">
                                    <h2 className="banner-title">
                                        LOREM IPSUM LOREM IPSUM
                    </h2>
                                    <p className="banner-text">
                                        Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
                    </p>
                                    <div className="banner-button">
                                        <span className="right-icon">
                                            <a href=""
                                                data-keyboard="true" data-toggle="modal"    data-keyboard="true" data-target="#feat-Reg" >
                                                <button type="button" className="banner-btn">
                                                    LOGIN/SIGNUP
                            </button>
                                            </a>

                                        </span>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        {/* <div>
                            <a href="#processflow" className="padding-01"> <img src={banner3} /> </a>
                        </div> */}
                        {/* <div>
                            <a href="#benefits" className="padding-01">  <img src={banner4} /> </a>
                        </div> */}
                        {/* <div>
                            <img src={banner1} />
                            <div className="caption">
                                <div className="col-md-7 col-sm-7 col-xs-12">
                                    <h2 className="banner-title">
                                        LOREM IPSUM LOREM IPSUM
                    </h2>
                                    <p className="banner-text">
                                        Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
                    </p>
                                    <div className="banner-button">
                                        <span className="right-icon">
                                            <a href="#login"
                                                data-keyboard="true" data-toggle="modal"    data-keyboard="true" data-target="#feat-Reg" >
                                                <button type="button" className="banner-btn">
                                                    LOGIN/SIGNUP
                            </button>
                                            </a>

                                        </span>                                 </div>
                                </div>
                            </div>
                        </div> */}
                    </Carousel>

                </section>
                <FinxRegistration {...this.props} />
                <Formik
              enableReinitialize={true}
              initialValues={{
                emailid:this.state.email,
                partyid:this.state.partyid,
                feedbackMsg:this.state.feedbackMsg,
                rank:this.state.rank,
                typeOfConcernList:this.state.typeOfConcernList1,
                typeOfConcern:""
            }}
            validationSchema={Yup.object().shape({
                //static validations
                rank:Yup.number()
                    .min(1, 'You must specify your reaction')
                    .max(5, 'rating range should be between 1-5'),
                feedbackMsg: Yup.string()
                    .matches(/^[a-zA-Z0-9\s,\-&.\/()]+$/, "Please enter valid Feedback Message. Allowed characters : alphnumeric,Space,comma,hyphen,ampersand,dot,forward Slash, Round Brackets"),
                typeOfConcern:Yup.string().when("rank",{
                    is:(rank)=>rank===1||rank===2?true:false,
                    then:Yup.string().required("You Must Select Type of Concern"),
                    otherwise:Yup.string()
                })
                }
            )}
              onSubmit={(values)=>{
                // if(values.rank === 0){
                //     warning("You Must Specify your Reaction",warningNotification);
                //     return;
                // }
                if(values.emailid === "")
                {
                    warning("EmailId can't be null",warningNotification);
                    return;
                }
                else if(values.partyid === "")
                {
                    warning("partyId can't be null",warningNotification);
                    return;
                }
                let data = {
                    partyid : values.partyid,
                    emailid: values.emailid,
                    rank: values.rank,
                    feedback_msg: values.feedbackMsg,
                    concernID:values.typeOfConcern
                }

                FeedbackService.PostFeedback(
                    data,
                    (cb) => {        
                        if (cb.status === "success") {
                          this.setState({ loading: false });
                          success("Thank you for your valuable Feedback!", successNotification);
                          window
                          .$("#feedback-modal")
                          .modal("hide");
                        } else {        
                          error(cb.status,errorNotification);
                          window
                          .$("#feedback-modal")
                          .modal("hide");
                          this.setState({ loading: false });
                        }
                      },
                      (err) => console.log("Error", err)
                  );
                
            }}
              render={({
                errors,
                touched,
                handleSubmit,
                setFieldValue,
                handleReset,
                values,
                handleChange
              }) => (
                  <React.Fragment>
                    <div
                      className="modal fade"
                      id="feedback-modal"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="myModalLabel"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                            <strong><h4 className="modal-title text-center" style={{fontWeight:"bold",marginBottom:20}} id="myModalLabel">
                                How was your overall experience with RCP?
                            </h4>
                            </strong>
                          </div>
                          <div className="modal-body">
                            <Form className="form-group">
                              <div className="form-group">
                                <div className="row">
                                   <div id="myDIV" className="col-md-12 col-sm-12 col-xs-12 text-center">
                                    <span name="rank" className="emoji" onClick={()=>{this.setState({rank: 1})}}>&#128544;</span>
                                    <span name="rank" className="emoji" onClick={()=>{this.setState({rank: 2})}}>&#128530;</span>
                                    <span name="rank" className="emoji" onClick={()=>{this.setState({rank: 3})}}>&#128529;</span>
                                    <span name="rank" className="emoji" onClick={()=>{this.setState({rank: 4})}}>&#128512;</span>
                                    <span name="rank" className="emoji" onClick={()=>{this.setState({rank: 5})}}>&#129321;</span>
                                   </div>
                                </div>
                                {["1",1,"2",2].includes(this.state.rank)&&
                                <div className="row">

                                <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                                    <label>
                                        Type Of Concern
                                        <span className="validation">
                                            {" "}*{" "}
                                        </span>
                                    </label>
                                        <Field
                                          as="select"
                                          name="typeOfConcern"
                                          id="typeOfConcern"
                                          style={{ border: "1px solid #fbc583",width:"90%", margin:"0px 5px 5px 3rem"  }}
                                        //   onChange={(e)=>setFieldValue("typeOfConcern",e.target.value)}
                                        onChange={handleChange}
                                          className={
                                            "form-control " +
                                            (errors.typeOfConcern &&
                                            touched.typeOfConcern
                                              ? " is-invalid"
                                              : "")
                                          }
                                      
                                        >
                                            <option value="">--Select--</option>
                                          {(
                                            values.typeOfConcernList || []
                                          ).map((item) => (
                                            <option
                                              key={item.concernID}
                                              value={item.concernID}
                                            >
                                              {item.concernName}
                                            </option>
                                          ))}
                                        </Field>
                                        <ErrorMessage
                                          name="typeOfConcern"
                                          component="div"
                                          className="validation"
                                        />
                                      </div>


                                </div>
              }
                                <div className="row">
                                   <div className="col-md-12 col-sm-12 col-xs-12">
                                  
                                    <Field
                                      rows="4"
                                      component="textarea"
                                      maxLength={255}
                                      name="feedbackMsg"
                                      id="feedbackMsg"
                                      placeholder="What can we do to improve?"
                                      autoComplete="feedbackMsg"
                                     onChange={handleChange}
                                      className={
                                        "form-control"
                                      }
                                      style={{ border: "1px solid #fbc583",width:"90%", margin:"0px 5px 5px 3rem"  }}
                                  
                                    />
                                     <ErrorMessage
                                          name="feedbackMsg"
                                          className="validation"
                                          component="div"
                                          style={{marginLeft:"42px"}}
                                        />
                                    <ErrorMessage
                                          name="rank"
                                          className="validation"
                                          component="div"
                                          style={{marginLeft:"42px"}}
                                        />
                                   </div>     
                                </div>
                              </div>
                            </Form>
                          </div>
                          <div className="modal-footer text-center">
                           
                            {/* <span style={{float:"center"}}> */}
                           
                            <button
                                type="submit"
                                className="btn btn-primary"
                                onClick={handleSubmit}
                            >
                              Send your Feedback
                            </button>
                            {/* <button
                              type="reset"
                              className="btn discount-table-heading"
                              onClick={handleReset}
                            >
                              Reset
                            </button> */}
                           
                            {/* </span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
            />    

                {/* <div class="modal fade" id="feedback-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel"></h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                    
                    <div className='wrapper'>
                        <div className='form-wrapper'>
                        <h2>Feedback Form</h2>
                        <form onSubmit={this.handleSubmit} noValidate>
                           
                            <div className='email'>
                            <label htmlFor="email">Email</label>
                            <input className="form-control" type='email' name='userName' value={this.state.userName} noValidate disabled readOnly/>
                            </div>
                        
                            <div className='submit'>
                            <input type="submit" value="Submit" />
                            </div>
                        </form>
                    </div>
                </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button>
                    </div>
                    </div>
                </div>
                </div>
             */}
            </React.Fragment>

            
        );
        }
    }
    
export default cfxroadmap;